import { type FC, Fragment } from "react"
import styled from "styled-components"

import { useTermsOfService } from "~/api/termsOfService"
import Layout from "~/themes/school/components/Layout"
import { PageTitle } from "~/themes/school/components/PageHeader"
import { fonts } from "~/themes/school/styles"
import { useTranslation } from "~/translations"

const TermsOfServicePage: FC = () => {
	const content = useTermsOfService()
	const t = useTranslation()

	return (
		<Layout>
			<PageTitle>{t("termsOfService")}</PageTitle>
			<Content>
				{content.introItems.map((item, index) => (
					<Text key={index}>{item}</Text>
				))}
				{content.sections.map((section, index) => (
					<Fragment key={index}>
						<Section>
							<NumberColumn>
								<SectionTitle>{index + 1}.</SectionTitle>
							</NumberColumn>
							<ContentColumn>
								<SectionTitle>{section.title}</SectionTitle>
							</ContentColumn>
						</Section>
						{section.items.map((item, itemIndex) => (
							<Section key={itemIndex}>
								<NumberColumn>
									{!section.skipItemNumbers && (
										<Text>
											{index + 1}.{itemIndex + 1}.
										</Text>
									)}
								</NumberColumn>
								<ContentColumn>
									<Text>{item}</Text>
								</ContentColumn>
							</Section>
						))}
					</Fragment>
				))}
			</Content>
		</Layout>
	)
}

const Content = styled.div`
	background-color: white;
	padding: 42px;
`

const Section = styled.div`
	display: flex;

	@media (max-width: 999px) {
		margin-left: 16px;
		margin-right: 16px;
	}
`

const NumberColumn = styled.div`
	flex: 0 0 42px;
	font-size: 14px;
`

const ContentColumn = styled.div`
	flex: 1 0 0;
`

const SectionTitle = styled.h2`
	font-family: ${fonts.quicksand};
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 8px;
`

const Text = styled.p`
	font-size: 14px;
	margin-bottom: 16px;
`

export default TermsOfServicePage
