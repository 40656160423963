import { useEffect, useLayoutEffect } from "react"
import { useLocation } from "react-router"

export default function useMatomo({ hasCookieConsent }: { hasCookieConsent: boolean }): void {
	const location = useLocation()

	useLayoutEffect(() => {
		window._paq = window._paq ?? []
	}, [])

	useEffect(() => {
		const domains = [initialPlatform.domain.platform]
		if (initialPlatform.domain.landingPage !== null) {
			domains.push(initialPlatform.domain.landingPage)
		}

		window._paq.push(["requireCookieConsent"])
		window._paq.push(["setTrackerUrl", "/analytics/matomo.php"])
		window._paq.push(["setSiteId", parseInt(initialPlatform.matomoSiteId)])
		window._paq.push(["setDomains", domains])

		const script = document.createElement("script")
		script.type = "text/javascript"
		script.async = true
		script.src = "/analytics/matomo.js"
		document.head.appendChild(script)

		return () => {
			document.head.removeChild(script)
		}
	}, [])

	useEffect(() => {
		if (hasCookieConsent) {
			window._paq.push(["rememberCookieConsentGiven"])
		}
	}, [hasCookieConsent])

	useEffect(() => {
		window._paq.push(["setCustomUrl", location.pathname])
		window._paq.push(["setDocumentTitle", document.title])
		window._paq.push(["trackPageView"])
	}, [location.pathname, location.search])
}
