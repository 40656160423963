import { Fragment } from "react"
import styled from "styled-components"

import { useTermsOfService } from "~/api/termsOfService"
import Layout from "~/themes/original/components/Layout"

const TermsOfServicePage: React.FC = () => {
	const content = useTermsOfService()

	return (
		<Layout>
			<Title>Terms of Service</Title>
			{content.introItems.map((item, index) => (
				<Text key={index}>{item}</Text>
			))}
			{content.sections.map((section, index) => (
				<Fragment key={index}>
					<Section>
						<NumberColumn>
							<SectionTitle>{index + 1}.</SectionTitle>
						</NumberColumn>
						<ContentColumn>
							<SectionTitle>{section.title}</SectionTitle>
						</ContentColumn>
					</Section>
					{section.items.map((item, itemIndex) => (
						<Section key={itemIndex}>
							<NumberColumn>
								{!section.skipItemNumbers && (
									<Item>
										{index + 1}.{itemIndex + 1}.
									</Item>
								)}
							</NumberColumn>
							<ContentColumn>
								<Item>{item}</Item>
							</ContentColumn>
						</Section>
					))}
				</Fragment>
			))}
		</Layout>
	)
}

const Title = styled.h1`
	font-weight: 500;
	font-size: 36px;
	color: #151d48;
	margin-bottom: 24px;

	@media (max-width: 999px) {
		margin-left: 16px;
		margin-right: 16px;
	}
`

const Text = styled.p`
	font-size: 14px;
	margin-bottom: 16px;

	@media (max-width: 999px) {
		margin-left: 16px;
		margin-right: 16px;
	}
`

const Section = styled.div`
	display: flex;

	@media (max-width: 999px) {
		margin-left: 16px;
		margin-right: 16px;
	}
`

const NumberColumn = styled.div`
	flex: 0 0 56px;
	font-size: 14px;
`

const ContentColumn = styled.div`
	flex: 1 0 0;
`

const SectionTitle = styled.h2`
	font-weight: 600;
	font-size: 20px;
	color: #151d48;
	margin-bottom: 8px;
`

const Item = styled.p`
	font-size: 14px;
	margin-bottom: 16px;
`

export default TermsOfServicePage
